import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import adiKailasPeak from "../../../Assets/images/adiKailasPeak.png";

export const TripHighlights = () => {
  return (
    <Box
      sx={{
        margin: "40px 10px",
        padding: "10px",
      }}
    >
      <Grid container spacing={2} >
        <Grid item sm={6} xs={12} display="flex" justifyContent="center">
          <img src={adiKailasPeak} style={{ maxWidth:"98%" }} alt="img" />
        </Grid>
        <Grid item sm={6} xs={12} display="flex" justifyContent="center">
          <Box className="cursiveFont">
            <Typography sx={{ color: "#a385c3", fontWeight: "700" ,fontSize:"1.7rem" }}>Our Services</Typography>
            <ul>
            <li> Pure Vegetarian meal (breakfast, lunch, dinner & tea)</li>
            <li>Hygienic home stays</li>
            <li>Experienced guide Support</li>
            <li>Exploring beautiful sites</li>
            <li>Transportation by 4- Wheeler</li>
          </ul>

          <Typography sx={{ color: "#a385c3", fontWeight: "700" ,fontSize:"1.7rem" }}>Trip Requirements</Typography>
            <ul>
            <li>Yatra Inner Line Permit</li>
            <li>Adhaar Card</li>
            <li>Passport Size Photo</li>
            <li>Covid Vaccination certificate</li>
          </ul>

          <Typography sx={{ color: "#a385c3", fontWeight: "700" ,fontSize:"1.7rem" }}>About Adi Kailash</Typography>
          <Typography>
          According to the Hindu mythology, Adi Kailash is the abode of Lord Shiva, his consort Parvati and their offspring Ganpati and Kartikey. Therefore, the spectacular Adi Kailash mountain range is considered as the most sacred mountain range in the world and is worshiped by many Hindus for its religious importance.
          </Typography>
          <br/>
          <Typography sx={{display:{lg:"block",xs:"none"}}}>Adi Kailash is an ancient holy place in the Himalayan Range, similar to Mount Kailash in Tibet. This abode of Lord Shiva in a remote area is worth having a Darshan. The journey to Adi Kailash is one of the most remarkable of these. Mount Adi Kailash is located in the Vyas valley of Pithoragarh district of Kumaon. This valley is home to the Rangs or the Shauka people who held an enviable monopoly in the Indo-Tibet trade for centuries before the Sino war of 1962.</Typography>
          </Box>
         
        </Grid>
      </Grid>
    </Box>
  );
};
