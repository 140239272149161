import AdiKailash from "../../../Assets/images/adiKailasPeak.png"
import omParvat from "../../../Assets/images/omParvat.png"
import kutiVillage from "../../../Assets/images/kutiVillage.png"
import kalapaniTemple from "../../../Assets/images/kalapaniTemple.png"
import pandavParvat from "../../../Assets/images/pandavParvat.png"
import kutiVillage2 from "../../../Assets/images/kutiVillage2.png"
import adiKailah1 from "../../../Assets/images/adiKailah1.jpg"
import lipuleikh from "../../../Assets/images/lipuleikh.JPG"


export const places = [
    {
        img: adiKailah1,
        name:"Delhi to Adi Kailash",
        pickup:"Pickup and Drop at Delhi",
        price: "30,000",
        days:"7 Days",
        nights: "6 Nights",
        includes: "We will be providing breakfast, lunch and dinner",
        link:"/delhi-to-adi-kailash"
        // img:[{img1: kutiVillage2},
        //     {img2: omParvat},
        //     {img3: kalapaniTemple},],
    },
    {
        img: kutiVillage,
        name:"Dehradun to Adi Kailash",
        pickup:"Pickup and Drop at Dehradun",
        price: "30,000",
        days:"7 Days",
        nights: "6 Nights",
        includes: "We will be providing breakfast, lunch and dinner",
        link:"/dehradun-to-adi-kailash"
    },
    {
        img: lipuleikh,
        name:"Haldwani to Adi Kailash",
        pickup:"Pickup and Drop at Haldwani",
        price: "28,000",
        days:"6 Days",
        nights: "5 Nights",
        includes: "We will be providing breakfast, lunch and dinner",
        link:"/haldwani-to-adi-kailash"
    },
    
]
