import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import mountain from "../../../Assets/images/adiKailasPeak.png";
import { places } from "./TravelCardData";
import "./TravelCard.css";
import { Link } from "react-router-dom";

export default function TravelCard() {
  console.log("placesssssssssssss", places);

  return (
    <>
      <Box>
        <Box sx={{ m: 4 }}>
          <Typography
            sx={{
              fontSize: { sm: "40px", xs: "32px" },
              fontWeight: "700",
              color: "#a385c3",
              textAlign: "center",
            }}
            className="cursiveFont"
          >
            Places to Visit
          </Typography>
        </Box>
        <Grid container spacing={3} sx={{ p: 2 }}>
          {places.map((item, index) => {
            console.log("itemmmm", item);
            return (
              <Grid
                item
                md={4}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <TravelCardComponent props={item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}

export function TravelCardComponent(prop) {
  return (
    <Card className="cursor-pointer">
      <Box
        height="250"
        // image={prop.props.img}

        className="img-container"
      >
        <img src={prop.props.img} alt="img" />
      </Box>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontSize: { sm: "21px", xs: "18px" }, fontWeight: 600 }}
        >
          {prop.props.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {prop.props.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Link to="/places-to-explore" style={{textDecoration:"none"}}>
        <Button size="small">View Gallery</Button></Link>
      </CardActions>
    </Card>
  );
}
