import { Box,Typography ,Button, Grid} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './HomePageHeader.css'
import headerVideo from "../../../Assets/videos/headerVideo.MP4"
import adventureIcon from "../../../Assets/images/adventure-icon.svg"
import safetyIcon from "../../../Assets/images/safetyIcon.svg"
import experienceIcon from "../../../Assets/images/experienceIcon.svg"
import memoriesIcon from "../../../Assets/images/memoriesIcon.png"
import { positions } from '@mui/system';
import { Link } from 'react-router-dom';
import SimpleImageSlider from "react-simple-image-slider";
import headerSlider1 from "../../../Assets/images/headerSlider1.png";
import headerSlider2 from "../../../Assets/images/headerSlider2.png";
import headerSlider3 from "../../../Assets/images/headerSlider3.png";
import headerSlider4 from "../../../Assets/images/headerSlider4.png";
import headerSlider5 from "../../../Assets/images/headerSlider5.png";
import headerSlider6 from "../../../Assets/images/headerSlider6.png";
import headerSlider7 from "../../../Assets/images/headerSlider7.png";
import headerSlider8 from "../../../Assets/images/headerSlider8.png";
import headerSlider9 from "../../../Assets/images/headerSlider9.png";

function HomePageHeader(){
  const [width, setWidth] = useState(100);
  
  function handleResize() {
    const containerWidth = document.getElementById('slider-container').offsetWidth;
    setWidth(containerWidth);
  }
  window.addEventListener('resize', handleResize);
  const images = [
    { url: headerSlider1 },
    { url: headerSlider2 },
    { url: headerSlider3 },
    { url: headerSlider4 },
    { url: headerSlider5 },
    { url: headerSlider6 },
    { url: headerSlider7 },
    { url: headerSlider8 },
    { url: headerSlider9 },
  ];
  // const videoEl = useRef(null);

  // const attemptPlay = () => {
  //   videoEl &&
  //     videoEl.current &&
  //     videoEl.current.play().catch(error => {
  //       console.error("Error attempting to play", error);
  //     });
  // };

  // useEffect(() => {
  //   attemptPlay();
  // }, []);
    return(
        <>
        {/* <div>
            <Box classname="headerBg">
        <img src={bgImg} style={{width:"100%", height:"600px"}}/>
            </Box>
        </div> */}
        <Box>
        <Box sx={{position:"relative"}}>
        <Box className='image-container' display="flex" justifyContent="center" style={{ marginTop:"55px"}}>
        {/* <video
          style={{ maxWidth: "100%", width: "100%", }}
          playsInline
          loop
          muted
          controls
          alt="All the devices"
          src={headerVideo}
          ref={videoEl}
          className="videoStyle"
        /> */}
        <SimpleImageSlider
        width={`${width}%`}
        height={550}
        images={images}
        showBullets={true}
        showNavs={true}
        className="slider"
      />
      <div className='gradient'></div>
        </Box>
        
    {/* <Box id="showcase"> */}
      <Box sx={{display:"flex",justifyContent:"center", backgroundColor:"red" }}>
      <Box sx={{position:"absolute",bottom:"40%" }}>
        <Box className="showcase-content">
          <Typography sx={{fontSize:{lg:"60px",sm:"36px",xs:"28px"},fontWeight:"800",color:"#efebe8", textAlign:"center"}} className="headText cursiveFont">
            Welcome To Om Namah Tourism
          </Typography>
          <Typography sx={{color:"#ffffff",fontSize:{md:"24px",xs:"19px"}, textAlign:"center", fontWeight:"500"}} className="cursiveFont">
          Plan Your Adventure with us!
          </Typography>
        </Box>
        <Box justifyContent="center" display="flex" sx={{mt:{sm:5,xs:2},}}>
        <Link to="/price" style={{ textDecoration: 'none' }}>
        <Button sx={{textTransform:"none"}} className="packageBtn cursiveFont">
            Our Packages
        </Button>
        </Link>
        </Box>
      </Box>
      </Box>
    {/* </Box> */}
    </Box>

    </Box>
        </>
    )
}
export default HomePageHeader;