import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import "./PreferredChoice.css"

export const PreferredChoice = () => {
    return (
        <div>
            <Box className="choiceBg" >
                <Box className="chooseHeader ">
                    <Box sx={{ width: { sm: "70%", xs: "100%" } }}>
                        <Typography sx={{ fontSize: { md: "42px", xs: "26px" }, textAlign: "center", fontWeight: "600", color: "#ffffff" }}>
                            Why Choose Us?
                        </Typography>
                        <Typography color="#ffffff" sx={{ textAlign: "center", fontSize: { md: "16px", xs: "14px" } }}>
                            We specialize in Adi Kailash trip for many years. We are accompanying with a variety of home stays, have best transport facilities to provide our clients with the best deals in the market. It’s about using our expert knowledge of holiday destinations and our network of friends across the world to make sure reality exceeds your expectations
                        </Typography>
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    <Grid item sm={8} className="whyChoose">
                        <Grid container spacing={2}>
                            <Grid item sm={6} className="chooseInner">
                                <Typography sx={{ textAlign: "center", fontSize: { sm: "24px", xs: "18px" }, color: "#ffffff" }} >
                                    Best Deals in the Market
                                </Typography>
                                <Typography color="#ffffff" sx={{ textAlign: "center", fontSize: { md: "15px", xs: "13px" } }}>
                                    We have comperatively low prices in the market making it the best deal for you.
                                </Typography>

                            </Grid>
                            <Grid item sm={6} className="chooseInner">
                                <Typography sx={{ textAlign: "center", fontSize: { sm: "24px", xs: "18px" }, color: "#ffffff" }}>
                                    Clients Trust
                                </Typography>
                                <Typography color="#ffffff" sx={{ textAlign: "center", fontSize: { md: "15px", xs: "13px" } }}>
                                Establishing, developing, and maintaining a solid relationship with a client is our top priority.
                                </Typography>

                            </Grid>
                            <Grid item sm={6} className="chooseInner">
                                <Typography sx={{ textAlign: "center", fontSize: { sm: "24px", xs: "18px" }, color: "#ffffff" }}>
                                    Expert Guides
                                </Typography>
                                <Typography color="#ffffff" sx={{ textAlign: "center", fontSize: { md: "15px", xs: "13px" } }}>
                                Expert Travel Guide stands for simplifying travel planning for people and Our guide has 5+ years of experience in that.
                                </Typography>

                            </Grid>
                            <Grid item sm={6} className="chooseInner">
                                <Typography sx={{ textAlign: "center", fontSize: { sm: "24px", xs: "18px" }, color: "#ffffff" }}>
                                    Recommendation
                                </Typography>
                                <Typography color="#ffffff" sx={{ textAlign: "center", fontSize: { md: "15px", xs: "13px" } }}>
                                We get most of the booking due to remommendations from the people we served.
                                </Typography>

                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item sm={4}>
                        <Box className='whatMakesUs'>
                            <Typography sx={{ fontSize: { md: "32px", xs: "21px" }, textAlign: "center", fontWeight: "600", color: "#a385c3" }}>
                                What makes us
                            </Typography>
                            <Typography>
                                Om Namah Tourism is a full-service travel management company. We are committed to our values of innovation, safety, professionalism, pride and fun as we strive to exceed our customers expectations.

                             We are committed to conducting ourselves to high standards, with respect, in an honest, kind and fair manner. We are proud of where we are from, where we go and who we are. We demonstrate pride in everything we do, while we support our community and our country.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
