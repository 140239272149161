import './App.css';
import { Routes, Route } from "react-router-dom";
import Navbar from './NavbarComponent/NavbarComponent';
import { HomePage } from './Pages/HomePage/HomePage';
import Price from './common/PriceCard/PriceCard';
import { DelhiToAdiDetails } from './Pages/DelhiToAdiDetails/DelhiToAdiDetails';
import { HaldwaniToAdiDetails } from './Pages/HaldwaniToAdiDetails/HaldwaniToAdiDetails';
import { DehradunToAdiDetails } from './Pages/DehradunToAdiDetails/DehradunToAdiDetails';
import Footer from './Footer/Footer';
import { Places } from './Pages/Places/Places';
import { Contact } from './Pages/Contact/Contact';


function App() {
  return (
    <div >
      <Navbar/>      
      <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/price" element={<Price/>}/>
          <Route path="/delhi-to-adi-kailash" element={<DelhiToAdiDetails/>}/>
          <Route path="/haldwani-to-adi-kailash" element={<HaldwaniToAdiDetails/>}/>
          <Route path="/dehradun-to-adi-kailash" element={<DehradunToAdiDetails/>}/>
          <Route path="/places-to-explore" element={<Places/>}/>
          <Route path="/contact" element={<Contact/>}/>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

// import './App.css';
// import React from 'react';
// import { Routes, Route } from "react-router-dom";
// import Navbar from './NavbarComponent/NavbarComponent';


// const App = () => {
//   return (
//     <div>
//       <Routes>
//           <Route path="test" element={<Navbar/>}/>
//       </Routes>
//     </div>
//   )
// }

// export default App;

