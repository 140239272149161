import AdiKailash from "../../../Assets/images/adiKailasPeak.png"
import omParvat from "../../../Assets/images/omParvat.png"
import kutiVillage from "../../../Assets/images/kutiVillage.png"
import kalapaniTemple from "../../../Assets/images/kalapaniTemple.png"
import pandavParvat from "../../../Assets/images/pandavParvat.png"
import kutiVillage2 from "../../../Assets/images/kutiVillage2.png"
export const places = [
    {
        img: AdiKailash,
        name:"Adi Kailash",
        description: "Adi Kailash - The Chota Kailash Yatra Situated at a fairly impressive altitude of 6,310 metres, Adi Kailash also referred to as Chota Kailash or Little Kailash is in one or the other way a replica of Mount Kailash in Tibet. It is situated in the Indian Territory in Pithoragarh district of Uttarakhand within a proximity to the Indo-Tibetan border."
    },
    {
        img: omParvat,
        name:"Om Parvat",
        description: "Om Parvat can be viewed on the route to the Kailash Manasarovar Yatra from the last camp below Lipulekh Pass.It has always been the sacred mountain for Hindus among all over the world. [5] Many trekkers to Adi Kailash often make a diversion to view Om Parvat. Om Parvat is located near Nabhi Dhang camp on Mount Kailash-Lake Manasarovar yatra route. "
    },
    {
        img: kutiVillage2,
        name:"Kuti Village",
        description: "Kuti Valley is a Himalayan valley situated in the Pithoragarh District, Kumaon division of the Uttarakhand state of India. Located in the eastern part of Uttarakhand, it is the last valley before the border with Tibet. It runs along a NW to SE axis, formed by the river Kuti Yankti, which is one of the headwaters of the Kali River that forms the boundary between India and Nepal in this region."
    },
    {
        img: kalapaniTemple,
        name:"Kalapani Temple",
        description: "Situated on the Kailash Mansasarovar route, at an altitude of 3600 mts, Kalapani is a blooming valley administered as a part of Pithoragarh district of Uttarakhand. Kalapani is also known as Vyas Valley.  Kalapani is embraced with pine, juniper, and Bhojpatra trees and the mighty Himalayas stand tall guarding it. The Kali River in Kalapani marks the border between India and Nepal."
    },
    {
        img: pandavParvat,
        name:"Pandav Parvat",
        description: "Five peaks standing together represents pandavas. It is situated in the Indian Territory in Pithoragarh district of Uttarakhand within a proximity to the Indo-Tibetan border."
    },
]
