import { Box } from "@mui/material";
import React from "react";
import "./HaldwaniToAdiDetails.css";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { timelineItemClasses } from "@mui/lab";

export const HaldwaniToAdiDetails = () => {
  return (
    // <div style={{color:"#000000",fontSize:"40px", marginTop:"100px"}}>Delhi to Adi Kailash</div>
    <Box>
      <Box className="delhiHeaderBox">
        <div style={{ color: "#ffffff", fontSize: "40px" }}>
          Haldwani to Adi Kailash
        </div>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Box
          sx={{
            color: "#4b647f",
            fontSize: "2.5rem",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Adi Kailash, Om Parvat from Haldwani
        </Box>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <TimelineCustomDot num="1" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box>
                <Box className="travelMenu">
                  Haldwani to Dharchula (280km approx.)
                </Box>
                <Box>
                  <ul>
                    <li>breakfast</li>
                    <li>board</li>
                    <li>
                      explore en-route:
                      <ul className="placesList">
                        <li>Almora kainchi Dham</li>
                        <li>Golu mandir </li>
                        <li>Jageshwar Dham</li>
                      </ul>
                    </li>
                    <li>lunch</li>
                    <li>Reach Dharchlula in the evening</li>
                    <li>Stay in Dharchula</li>
                    <li>Dinner</li>
                  </ul>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <TimelineCustomDot num="2" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box>
                <Box className="travelMenu">Stay in Dharchula</Box>
                <Box>
                  <ul>
                    <li>breakfast , lunch, dinner</li>
                    <li>Medical check </li>
                    <li>Permit</li>
                    <li className="placesList">
                      Explore Dharchula local Market Nepal
                    </li>
                  </ul>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <TimelineCustomDot num="3" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box>
                <Box className="travelMenu">Dharchula to Napalchyu</Box>
                <Box>
                  <ul>
                    <li>breakfast</li>
                    <li>
                      explore :
                      <ul className="placesList">
                        <li>Pelsethi WaterFall</li>
                        <li>Thakthi WaterFall </li>
                        <li>Chiyalekh Temple Dharshan</li>
                      </ul>
                    </li>
                    <li>lunch</li>
                    <li>Reach Napalchyu in the evening</li>
                    <li>Stay in Napalchyu</li>
                    <li>Dinner</li>
                  </ul>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <TimelineCustomDot num="4" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box>
                <Box className="travelMenu">Napalchyu to adi Kailash</Box>
                <Box>
                  <ul>
                    <li>breakfast in Napalchyu</li>
                    <li>
                      explore :
                      <ul className="placesList">
                        <li> Nabi (Mano Kamna Mandir)</li>
                        <li>Pandav Kila</li>
                        <li>Kunti Parvat</li>
                        <li>Pandav Parvat</li>
                        <li>Shiv Mandir</li>
                        <li>Parvati Sarovar</li>
                        <li>Parvati Mukut</li>
                        <li>Gauri Kund</li>
                      </ul>
                    </li>
                    <li>lunch in Joling kang</li>
                    <li>Return Napalchyu in the evening</li>
                    <li>Stay ,dinner in Napalchyu</li>
                  </ul>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <TimelineCustomDot num="5" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box>
                <Box className="travelMenu"> Napalchyu To Om Parvat</Box>
                <Box>
                  <ul>
                    <li>breakfast in Napalchyu</li>
                    <li>
                      explore :Nag Parvat+Kali Mandir+Ved Vyas Gufa+Om Parva
                      <ul className="placesList">
                        <li> Nag Parvat</li>
                        <li>Kali Mandir</li>
                        <li>Ved Vyas Gufa</li>
                        <li>Om Parvat</li>
                      </ul>
                    </li>
                    <li>lunch in Kalapani</li>
                    <li>Return Om Prvat to Dharchula</li>
                    <li>Stay ,dinner in Dharchula</li>
                  </ul>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <TimelineCustomDot num="6" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box>
                <Box className="travelMenu">Dharchula to Haldwani</Box>
                <Box>
                  <ul>
                    <li>Drop from dharchula to Haldwani</li>
                    <li>breakfast ,lunch, dinner</li>
                  </ul>
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Box>
    </Box>
  );
};

export const TimelineCustomDot = (props) => {
  return (
    <Box>
      <Box sx={{ color: "#000000" }}>Day{props.num}</Box>
    </Box>
  );
};
