import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import mountain from "../../../Assets/images/adiKailasPeak.png";
import { places } from "./PriceCardData";
import "./PriceCard.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import NightShelterIcon from '@mui/icons-material/NightShelter';
import LightModeIcon from '@mui/icons-material/LightMode';
import {  useNavigate } from "react-router-dom";

export default function Price() {

  return (
    <>
      <Box sx={{mt:10}}>
        <Box sx={{ m: 4 }}>
          <Typography
            sx={{
              fontSize: { sm: "40px", xs: "32px" },
              fontWeight: "700",
              color: "#a385c3",
              textAlign: "center",
            }}
            className="cursiveFont"
          >
            Packages
          </Typography>
        </Box>
        <Grid container spacing={3} sx={{ p: 2 }}>
          {places.map((item, index) => {
            console.log("itemmmm", item);
            return (
              <Grid
                item
                md={4}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <PriceCard props={item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}

export function PriceCard(prop) {
  const navigate = useNavigate()
  const link= "wa.link/4hq70m"
  const openWhatsap = ()=>{
    window.open(`http://${link}`)
  }
  
  return (
    <Card className="cursor-pointer fixed-size">
      <Box
        height="250"
        // image={prop.props.img}
        className="img-container"
      >
        <img src={prop.props.img} alt="img" />
      </Box>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            fontSize: { sm: "21px", xs: "18px" },
            fontWeight: 600,
            borderBottom: "1px solid #bebebe",
          }}
          p={1}
        >
          {prop.props.name} <br />
          <Typography variant="body2" color="text.secondary">
          {prop.props.pickup}
        </Typography>
        </Typography>
        
        <Typography variant="body2" color="text.secondary">
          {prop.props.description}
        </Typography>

        <Grid container spacing={2}  p={1}>
          <Grid item md={4}>
            <Typography variant="body2" color="text.secondary" display="flex" alignItems="center">
              {/* <b> Price :</b>  */}
              <CurrencyRupeeIcon sx={{width:"15px",color:"green" }} />
              {prop.props.price + "  ₹"}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body2" color="text.secondary" display="flex" alignItems="center">
              {/* <b> Duration :</b> */}
              <LightModeIcon   sx={{width:"15px",color:"green" }}/>
              {prop.props.days}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body2"  color="text.secondary" display="flex" alignItems="center">
              {/* <b> Duration :</b> */}
              <NightShelterIcon   sx={{width:"15px",color:"green" }}/>
              {prop.props.nights}
            </Typography>
          </Grid>
          <Grid item md={12} >
            <Typography variant="body2" color="text.secondary" display="flex" alignItems="center" >
              {/* <b> Including :</b> */}
              <AddIcon  sx={{width:"15px", color:"green" }} />
              {prop.props.includes}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container spacing={3} pl={2}>
          <Grid item>
            <Button
              className="bg-green"
              style={{ backgroundColor: "green", color: "white" }}
              size="small"
              sx={{ p: 1 }}
              onClick={openWhatsap}
            >
              Enquire Now
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="bg-green"
              style={{ backgroundColor: "green", color: "white" }}
              size="small"
              sx={{ p: 1 }}
              onClick={()=>navigate(prop.props.link)}
            >
              View Details
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
