import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import comanyLogo from "../../Assets/images/logoNav.PNG";
import adiKailash from "../../Assets/images/adiKailasPeak.png";
import omParvat from "../../Assets/images/omParvat.png";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import galleryImg1 from "../../Assets/images/galleryImg4.jpg"
import galleryImg5 from "../../Assets/images/galleryImg6.jpg"


const Footer = () => {
  return (
    <>
      <Box sx={{ color: "#ffffff", backgroundColor: "black",  }} className="footerGrid">
        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item sm={4} xs={12} sx={{ml:{sm:8, xs:2}}}>
            <Typography className="footer-header">Om Namah Tourism</Typography>
            <Typography>
              A good plus of traveling is that often you get new skills without
              difficulty and without even noticing it.
            </Typography>
            <img src={comanyLogo} alt="company-logo" width={100} />
          </Grid>
          <Grid item sm={3} xs={12} mt={2}>
            <Typography className="footer-header">Contacts</Typography>
            <Box>
              <Box className="footer-inner">
                <CallIcon />
                <Typography>+91 94568 91600</Typography>
              </Box>
              <Box className="footer-inner">
                <LocationOnIcon />
                <Typography>Dharchula, Uttarakhand</Typography>
              </Box>
              <Box className="footer-inner">
                <AccessTimeIcon />
                <Typography>Mon - Sat 8.00 - 18.00</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "50%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
               <a href="https://www.facebook.com/profile.php?id=100083020224415">
               <FacebookIcon />
               </a>
               <a href="http://instagram.com/adi.kailash_yatra">
                <InstagramIcon /></a>
               <a href="https://youtu.be/ZYa5svjMAyg"><YouTubeIcon /></a>
              </Box>
            </Box>
          </Grid>
          <Grid sm={3} xs={12} mt={2}>
            <Typography className="footer-header">Recent Trips</Typography>
            <Box sx={{ diplay: "flex" }}>
              <img
                src={adiKailash}
                className="footer-images"
                alt="company-logo"
                width={80}
              />
              <img
                src={galleryImg1}
                className="footer-images"
                alt="company-logo"
                width={80}
              />
              <img
                src={galleryImg5}
                className="footer-images"
                alt="company-logo"
                width={80}
              />
            </Box>
            <Box sx={{ diplay: "flex" }}>
              <img
                src={omParvat}
                className="footer-images"
                alt="company-logo"
                width={80}
              />
              <img
                src={omParvat}
                className="footer-images"
                alt="company-logo"
                width={80}
              />
              <img
                src={galleryImg1}
                className="footer-images"
                alt="company-logo"
                width={80}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
