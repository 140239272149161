import { Box } from '@mui/system'
import React from 'react'
import "./Contact.css"
import ContactImg from "../../../Assets/images/Contact.jpg"
import { Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

export const Contact = () => {
    const navigate = useNavigate()
  const link= "wa.link/4hq70m"
  const openWhatsap = ()=>{
    window.open(`http://${link}`)
  }
  return (

    <Box>
        <Box className="contactBg" display="flex" justifyContent="center" alignItems="center">
            <Typography sx={{fontSize:{sm:"42px",xs:"32px"}, color:"#ffffff"}}>Contact Us</Typography>

        </Box>
        <Box className="conversation">
            <Typography sx={{fontSize:"22px" , fontWeight:"600" ,textAlign:"center"}}>Let's start the Conversation</Typography>
            <Typography sx={{fontSize:"16px" ,mt:2 ,textAlign:"center"}}>We hope you enjoy our packages as much as we enjoy offering them to you. If you have any questions or comments, please don’t hesitate to contact us.</Typography>
            <Box display="flex" justifyContent="center">
            <Button className="chatBtn" onClick={openWhatsap}>Chat with us</Button>
            </Box>
            <Grid container p={3}>
                <Grid item sm={6} xs={12}>
                <Typography sx={{fontSize:"17px" , fontWeight:"600"}}>Call Us:</Typography>
                <Typography>+91 94568 91600</Typography>
                <Typography sx={{fontSize:"17px" , fontWeight:"600",mt:2}}>Email Us:</Typography>
                <Typography>adikailashyatra8@gmail.com</Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                <Typography sx={{fontSize:"17px" , fontWeight:"600", mt:3}}>Follow Us:</Typography>
               
                <Box display="flex" gap="10px" mt={2}>
                <Box className="cursor-pointer nav-items">
                 <a href="https://www.facebook.com/profile.php?id=100083020224415">
                 <FacebookIcon sx={{ color: "blue" }} />
                 </a>
                </Box>
                <Box className="cursor-pointer nav-items">
                  <a href="http://instagram.com/adi.kailash_yatra">
                  <InstagramIcon sx={{ color: "#8a3ab9" }} />
                  </a>
                </Box>
                <Box className="cursor-pointer nav-items">
                  <a href="https://youtu.be/ZYa5svjMAyg">
                  <YouTubeIcon sx={{ color: "red" }} />
                  </a>
                  
                </Box>
                </Box>
                </Grid>
            </Grid>
        </Box>
    </Box>
    )
}
