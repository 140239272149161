import React from 'react'
import { PreferredChoice } from '../../common/PreferredChoice/PreferredChoice'
import Price from '../../common/PriceCard/PriceCard'
import TravelCard from '../../common/TravelCard/TravelCard'
import { TripHighlights } from '../../common/TripHighlights/TripHighlights'
import HomePageHeader from '../HomePageHeader/HomePageHeader'

export const HomePage = () => {
  return (
    <div style={{backgroundColor:"#f5f3f7"}}>
        <HomePageHeader/>
        <TripHighlights/>
        <Price/>
        <TravelCard/>
        <PreferredChoice/>

    </div>
  )
}
