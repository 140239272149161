import { Box, Typography ,ImageList ,ImageListItem  } from '@mui/material'
import React from 'react'
import galleryImg1 from "../../../Assets/images/galleryImg1.JPG"
import galleryImg2 from "../../../Assets/images/galleryImg2.JPG"
import galleryImg3 from "../../../Assets/images/galleryImg3.JPG"
import galleryImg4 from "../../../Assets/images/galleryImg4.jpg"
import galleryImg5 from "../../../Assets/images/galleryImg5.jpg"
import galleryImg6 from "../../../Assets/images/galleryImg6.jpg"
import galleryImg7 from "../../../Assets/images/galleryImg7.jpg"
import galleryImg8 from "../../../Assets/images/galleryImg8.jpg"
import galleryImg9 from "../../../Assets/images/galleryImg9.jpg"
import galleryImg10 from "../../../Assets/images/galleryImg10.JPG"
import galleryImg11 from "../../../Assets/images/galleryImg11.jpg"
import galleryImg12 from "../../../Assets/images/galleryImg12.jpg"
import galleryImg13 from "../../../Assets/images/galleryImg13.jpg"
import galleryImg14 from "../../../Assets/images/galleryImg14.jpg"
import galleryImg15 from "../../../Assets/images/galleryImg15.webp"
import galleryImg16 from "../../../Assets/images/galleryImg16.webp"
import galleryImg17 from "../../../Assets/images/galleryImg17.webp"
import galleryImg18 from "../../../Assets/images/galleryImg18.webp"
import galleryImg19 from "../../../Assets/images/galleryImg19.webp"
import galleryImg20 from "../../../Assets/images/galleryImg20.webp"


  const itemData = [
    {
      img: galleryImg1,
      rows: 2,
      cols: 2,
    },
    {
      img:galleryImg2,
    },
    {
      img:galleryImg3,
    },
    {
      img:galleryImg4,
      cols: 2,
    },
    {
      img: galleryImg5,
      cols: 2,
    },
    {
      img: galleryImg6,
      rows: 2,
      cols: 2,
    },
    {
      img:galleryImg7,
    },
    {
      img: galleryImg8,
    },
    {
      img: galleryImg9,
      rows: 2,
      cols: 2,
    },
    {
      img: galleryImg10,
    },
    {
      img: galleryImg11,
    },
    {
      img:galleryImg12,
      cols: 2,
    },
    {
      img:galleryImg13,
    },
    {
      img:galleryImg14,
    },
    {
      img: galleryImg19,
      rows: 2,
      cols: 2,
    },
    {
      img: galleryImg15,
    },
    {
      img:galleryImg16,
      cols: 2,
    },
    {
      img:galleryImg17,
    },
    {
      img:galleryImg18,
    },
    {
      img: galleryImg20,
      cols: 2,
    },
  
    
  ];
  



export const Places = () => {
  return (
    <Box sx={{marginTop:"70px"}}>
        <Box>
            <Typography sx={{
              fontSize: { sm: "40px", xs: "32px" },
              fontWeight: "700",
              color: "#a385c3",
              textAlign: "center",
            }}
            className="cursiveFont">Places Gallery</Typography>

<ImageList variant="masonry" cols={2} gap={8}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt="img"
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
        </Box>
        <Box>

        </Box>

    </Box>
  )
}
