import * as React from "react";
import "./NavbarComponent.css";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
// import logoNav from "../../Assets/images/logoNew.PNG";
import omLogo from "../../Assets/images/omLogo.png";
import { Link, Link as Links } from "react-router-dom";

const drawerWidth = 240;
const navItems = [{name:"Home" , link:"/"}, {name:"Gallery" ,link: "places-to-explore"},{ name:"Packages" ,  link:"/price"}, {name:"Contact", link:"contact"}];

const Navbar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box>
        <Typography
          variant="h6"
          component="div"
          sx={{
            display: { sm: "block" },
          }}
        >
          <img src={omLogo} alt="logo" className="logoStyle" />
        </Typography>
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
             <Link to={item.link} style={{textDecoration:"none"}}>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav">
          <Toolbar sx={{ backgroundColor: "#f5f3f7" ,height:{sm:"80px",xs:"50px"}}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon sx={{ color: "#000000" }} />
            </IconButton>
            <Box
              className="navbar-home "
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#333333",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    display: { sm: "block" },
                  }}
                >
                  <img
                    src={omLogo}
                    alt="logo"
                    className="logoStyle cursor-pointer"
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", sm: "block" },
                }}
              >
                {navItems.map((item, index) => (
                 <Link to={item.link} style={{textDecoration:"none"}}>
                  <Button
                    key={item.index}
                    sx={{ color: "#000000", mr: 2 }}
                    className="cursiveFont nav-items"
                  >
                    {item.name}
                  </Button>
                 </Link>
                ))}
              </Box>

              <Box display="flex" gap="10px">
                <Box className="cursor-pointer nav-items">
                 <a href="https://www.facebook.com/profile.php?id=100083020224415">
                 <FacebookIcon sx={{ color: "blue" }} />
                 </a>
                </Box>
                <Box className="cursor-pointer nav-items">
                  <a href="http://instagram.com/adi.kailash_yatra">
                  <InstagramIcon sx={{ color: "#8a3ab9" }} />
                  </a>
                </Box>
                <Box className="cursor-pointer nav-items">
                  <a href="https://youtu.be/ZYa5svjMAyg">
                  <YouTubeIcon sx={{ color: "red" }} />
                  </a>
                  
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
};
export default Navbar;
